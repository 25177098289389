import { COLORS } from './common';

export default {
  label: 'Product',
  name: 'product',
  component: 'group',
  description:
    'Add to cart, back in stock, badges, color swatches, quantity selector, reviews',
  fields: [
    {
      label: 'Details',
      name: 'details',
      component: 'group',
      description: 'Settings for product details and extra information: product type',
      fields: [
        {
          label: 'Product Title Mobile Placement',
          name: 'productTitleMobilePlacement',
          component: 'select',
          options: [
            {
              label: 'Under Image',
              value: 'underImage',
            },
            {
              label: 'Above Image',
              value: 'aboveImage',
            },
          ],
        },
        {
          label: 'Enable Product Type',
          name: 'enabledProductType',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Color Name',
          name: 'enabledColorName',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Shop Pay Installments',
          name: 'enabledShopPayInstallments',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Final Sale Text',
          name: 'finalSaleText',
          component: 'text',
        },
      ],
      defaultValue: {
        productTitleMobilePlacement: 'aboveImage',
        enabledProductType: true,
        enabledColorName: true,
      },
    }, 
    {
      label: 'Product Options',
      name: 'options',
      component: 'group',
      description: 'Settings for product options: qty, swtaches, etc.',
      fields: [
        {
          label: 'Disable Group History',
          name: 'disableGroupHistory',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          description: 'Disable group history when changing color option. This will allow the user to return to the previous page instead of the color.',
        },
        {
          label: 'Enable Sold Out Color Swatch',
          name: 'enabledSoldOutColorSwatch',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Group Tabs',
          name: 'groupTabs',
          component: 'group',
          description: 'Settings for group tabs',
          fields: [
            {
              label: 'Enable Group Tabs',
              name: 'enabledGroupTabs',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Enable Group Tabs Link',
              name: 'enabledGroupTabsLink',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Product Type for Group Tabs',
              name: 'productTypeForGroupTabs',
              component: 'group-list',
              itemProps: {
                label: '{{item.productType}}',
              },
              defaultItem: {
                productType: 'Product Type',
              },
              fields: [
                {
                  label: 'Product Type',
                  name: 'productType',
                  component: 'text',
                },
              ],
            },
          ],
        },
        {
          label: 'Group Options',
          name: 'groupOptions',
          component: 'group',
          description: 'Settings for group options',
          fields: [
            {
              label: 'Enable Group Options',
              name: 'enabledGroupOptions',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Enable Group Options Link',
              name: 'enabledGroupOptionsLink',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Product Type for Group Options',
              name: 'productTypeForGroupOptions',
              component: 'group-list',
              itemProps: {
                label: '{{item.productType}}',
              },
              defaultItem: {
                productType: 'Product Type',
              },
              fields: [
                {
                  label: 'Product Type',
                  name: 'productType',
                  component: 'text',
                },
              ],
            },
          ],
        },
        {
          label: 'Enable Low Inventory',
          name: 'enabledLowInventory',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          description: 'Show low inventory message when qty is less than the number designed',
        },
        {
          label: 'Low Inventory Qty',
          name: 'lowInventoryQty',
          component: 'number',
          description: 'If Sold Out Qty is enabled, the Low Inventory Qty will be the sum of Sold Out Qty and Low Inventory Qty',
          defaultValue: 10,
        },
        {
          label: 'Low Inventory Message',
          name: 'lowInventoryMessage',
          component: 'text',
        },
        {
          label: 'Enable Custom Sold Out Qty',
          name: 'enabledCustomSoldOutQty',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        { 
          label: 'Sold Out Qty', 
          name: 'soldOutQty', 
          component: 'number' 
        },
      ],
      defaultValue: {
        disableGroupHistory: false,
        enabledSoldOutColorSwatch: true,
        enabledCustomSoldOutQty: false,
        enabledGroupTabs: false,
        enabledGroupTabsLink: false,
      },
    },
    
    {
      label: 'Product Carousel',
      name: 'productCarousel',
      component: 'group',
      description: 'Enable Product Carousel on desktop',
      fields: [
        {
          label: 'Enable Carousel on Desktop',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Zoom',
          name: 'enableZoom',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabled: true,
        enableZoom: true,
      },
    },
    {
      label: 'Add To Cart',
      name: 'addToCart',
      component: 'group',
      description: 'Add to cart, sold out, presale text, subtext',
      fields: [
        {
          label: 'Add To Cart Text',
          name: 'addToCartText',
          component: 'text',
        },
        {
          label: 'Sold Out Text',
          name: 'soldOutText',
          component: 'text',
        },
        {
          label: 'Preorder Text',
          name: 'preorderText',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'text',
          description: 'Text below the Add To Cart button',
        },
      ],
      defaultValue: {
        addToCartText: 'Add To Cart',
        soldOutText: 'Sold Out',
        preorderText: 'Preorder',
        subtext: '',
      },
    },
    {
      label: 'Back In Stock',
      name: 'backInStock',
      component: 'group',
      description: 'Enable notifications, notify me text, modal texts',
      fields: [
        {
          label: 'Enable Notifications',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Notify Me Text',
          name: 'notifyMeText',
          component: 'text',
        },
        {
          label: 'Modal Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Modal Subtext',
          name: 'subtext',
          component: 'text',
        },
        {
          label: 'Modal Submit Text',
          name: 'submitText',
          component: 'text',
        },
      ],
      defaultValue: {
        enabled: false,
        notifyMeText: 'Notify me when available',
        heading: 'Notify me when available',
        subtext: `Enter your email below and we'll notify you when this product is back in stock.`,
        submitText: 'Submit',
      },
    },
    {
      label: 'Breadcrumbs',
      name: 'breadcrumbs',
      component: 'group',
      description: 'Breadcrumbs settings',
      fields: [
        {
          label: 'Enable Breadcrumbs',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: "Men's",
          name: 'mens',
          component: 'group',
          description: "Men's Categories",
          fields: [ 
            {
              label: "Men's Tops",
              name: 'tops',
              component: 'group',
              fields: [ 
                {
                  label: 'Collections',
                  name: 'collections',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.productType}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      name: 'productType',
                      label: 'Product Type',
                    },
                    {
                      component: 'link',
                      name: 'collection',
                      label: 'Category Collection',
                      description: 'Select only a collection',
                    },
                  ],
                },
                {
                  component: 'link',
                  name: 'collection',
                  label: 'Category Collection',
                  description: 'Select only a collection',
                },
              ],
            },
            {
              label: "Men's Bottoms",
              name: 'bottoms',
              component: 'group',
              fields: [ 
                {
                  label: 'Collections',
                  name: 'collections',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.productType}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      name: 'productType',
                      label: 'Product Type',
                    },
                    {
                      component: 'link',
                      name: 'collection',
                      label: 'Category Collection',
                      description: 'Select only a collection',
                    },
                  ],
                },
                {
                  component: 'link',
                  name: 'collection',
                  label: 'Category Collection',
                  description: 'Select only a collection',
                },
              ],
            },
            {
              label: "Men's More",
              name: 'more',
              component: 'group',
              fields: [ 
                {
                  label: 'Collections',
                  name: 'collections',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.productType}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      name: 'productType',
                      label: 'Product Type',
                    },
                    {
                      component: 'link',
                      name: 'collection',
                      label: 'Category Collection',
                      description: 'Select only a collection',
                    },
                  ],
                },
                {
                  component: 'link',
                  name: 'collection',
                  label: 'Category Collection',
                  description: 'Select only a collection',
                },
              ],
            },
            {
              component: 'link',
              name: 'collection',
              label: 'Category Collection',
              description: 'Select only a collection',
            },
          ],
        },
        {
          label: "Women's",
          name: 'womens',
          component: 'group',
          description: "Women's Categories",
          fields: [ 
            {
              label: "Women's Tops",
              name: 'tops',
              component: 'group',
              fields: [ 
                {
                  label: 'Collections',
                  name: 'collections',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.productType}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      name: 'productType',
                      label: 'Product Type',
                    },
                    {
                      component: 'link',
                      name: 'collection',
                      label: 'Category Collection',
                      description: 'Select only a collection',
                    },
                  ],
                },
                {
                  component: 'link',
                  name: 'collection',
                  label: 'Category Collection',
                  description: 'Select only a collection',
                },
              ],
            },
            {
              label: "Women's Bottoms",
              name: 'bottoms',
              component: 'group',
              fields: [ 
                {
                  label: 'Collections',
                  name: 'collections',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.productType}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      name: 'productType',
                      label: 'Product Type',
                    },
                    {
                      component: 'link',
                      name: 'collection',
                      label: 'Category Collection',
                      description: 'Select only a collection',
                    },
                  ],
                },
                {
                  component: 'link',
                  name: 'collection',
                  label: 'Category Collection',
                  description: 'Select only a collection',
                },
              ],
            },
            {
              label: "Women's More",
              name: 'more',
              component: 'group',
              fields: [ 
                {
                  label: 'Collections',
                  name: 'collections',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.productType}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      name: 'productType',
                      label: 'Product Type',
                    },
                    {
                      component: 'link',
                      name: 'collection',
                      label: 'Category Collection',
                      description: 'Select only a collection',
                    },
                  ],
                },
                {
                  component: 'link',
                  name: 'collection',
                  label: 'Category Collection',
                  description: 'Select only a collection',
                },
              ],
            },
            {
              component: 'link',
              name: 'collection',
              label: 'Category Collection',
              description: 'Select only a collection',
            },
          ],
        }
      ],
    },
    {
      label: 'Badges',
      name: 'badges',
      component: 'group',
      description: 'Badge colors',
      fields: [
        {
          label: 'Badge Colors',
          name: 'badgeColors',
          component: 'group-list',
          description:
            'Note: product badges are set up via Shopify tags using the format "badge::Some Value"',
          itemProps: {
            label: '{{item.tag}}',
          },
          fields: [
            {
              label: 'Tag Value',
              name: 'tag',
              component: 'text',
              description:
                'Letter casing must be same as tag value in Shopify, e.g. "New", "Sale"',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'select',
              options: COLORS,
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'select',
              options: COLORS,
            },
          ],
          defaultItem: {
            bgColor: 'var(--black)',
            textColor: 'var(--white)',
          },
          defaultValue: [
            {
              tag: 'Draft',
              bgColor: 'var(--mediumGray)',
              textColor: 'var(--white)',
            },
            {
              tag: 'Best Seller',
              bgColor: 'var(--black)',
              textColor: 'var(--white)',
            },
            {
              tag: 'New',
              bgColor: 'var(--secondary)',
              textColor: 'var(--white)',
            },
            {
              tag: 'Sale',
              bgColor: 'var(--primary)',
              textColor: 'var(--white)',
            },
          ],
        },
        {
          label: 'Show multiple badges',
          name: 'badgesMultiple',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          component: 'number',
          name: 'badgesMultipleNumber',
          label: 'Number of badges to show',
          description: 'A number field',
          defaultValue: 1,
        },
        {
          label: 'Sold Out Badge',
          name: 'soldOutBadge',
          component: 'group',
          fields: [
            {
              label: 'Enable Sold Out Badge',
              name: 'enabled',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
              defaultValue: false,
            },
            {
              label: 'Sold Out Text',
              name: 'text',
              component: 'text',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'select',
              options: COLORS,
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'select',
              options: COLORS,
            },
          ],
        }
      ],
    },
    {
      label: 'Colors',
      name: 'colors',
      component: 'group',
      description: 'Color swatches',
      fields: [
        {
          label: 'Color Swatches',
          name: 'swatches',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          defaultItem: {
            name: 'New Color',
          },
          fields: [
            {
              label: 'Color Name',
              name: 'name',
              component: 'text',
            },
            {
              label: 'Color',
              name: 'color',
              component: 'color',
            },
            {
              label: 'Secondary Color',
              name: 'secondaryColor',
              component: 'color',
            },
            {
              name: 'image',
              label: 'Image',
              component: 'image',
              description:
                'If provided, image will overlay the color.\nEnsure image is no more than 2KB in size',
            },
            {
              label: 'Cutoff Percentage',
              name: 'cutoffPercentage',
              component: 'number',
              description:
                'Percentage of main color or image to show',
            },
            {
              label: 'Angle',
              name: 'angle',
              component: 'number',
              description: 'Angle of gradient',
            }
          ],
          defaultValue: [
            {
              name: 'Black',
              color: '#000000',
            },
            {
              name: 'White',
              color: '#FFFFFF',
            },
          ],
        },
      ],
    },
    {
      label: 'Quantity Selector',
      name: 'quantitySelector',
      component: 'group',
      description: 'Enable',
      fields: [
        {
          label: 'Enable Quantity Selector',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabled: false,
      },
    },
    {
      label: 'Size Guides',
      name: 'sizeGuides',
      component: 'group',
      description: 'Add size guide for product types',
      fields: [
        {
          label: 'Size Guide',
          name: 'sizeGuide',
          component: 'group-list',
          description: 'Add size guide for product types',
          itemProps: {
            label: '{{item.productType.productType}}',
          },
          fields: [
            {
              label: 'Product Types',
              name: 'productTypes',
              component: 'group-list',
              itemProps: {
                label: '{{item.productType}}',
              },
              defaultItem: {
                productType: 'Product Type',
              },
              fields: [
                {
                  label: 'Product Type',
                  name: 'productType',
                  component: 'text',
                },
              ],
            },
            {
              label: 'Columns',
              name: 'columns',
              component: 'group-list',
              description: 'Add columns like "Size", "Waist", "Length", etc.',
              itemProps: {
                label: '{{item.name}}',
              },
              defaultItem: {
                name: 'Column',
              },
              fields: [
                {
                  label: 'Name',
                  name: 'name',
                  component: 'text',
                },
              ],
            },
            {
              label: 'Rows',
              name: 'rows',
              component: 'group-list',
              description: 'Add rows with values for each column',
              defaultItem: {
                name: 'Row',
              },
              fields: [
                {
                  label: 'Values',
                  name: 'values',
                  component: 'group-list',
                  description: 'Add values for row',
                  itemProps: {
                    label: '{{item.value}}',
                  },
                  defaultItem: {
                    name: 'Value',
                  },
                  fields: [
                    {
                      label: 'Value',
                      name: 'value',
                      component: 'text',
                    },
                  ],
                },
              ],
            },
          ]
        }
      ],
    },
    {
      label: 'Trust Builders',
      name: 'trustBuilders',
      component: 'group',
      fields: [
        {
          label: 'Trust Builders',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        }
      ],
      defaultValue: {
        enabled: false,
      },
    },
    {
      label: 'Reviews',
      name: 'reviews',
      component: 'group',
      description: 'Enable star rating',
      fields: [
        {
          label: 'Enable Star Rating',
          name: 'enabledStarRating',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Review Widget',
          name: 'enabledReviewWidget',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabledStarRating: true,
        enabledReviewWidget: true,
      },
    },
    {
      label: 'Upsell Products',
      name: 'upsellProducts',
      component: 'group',
      description: 'Upsell products settings',
      fields: [
        {
          label: 'Message',
          name: 'message',
          component: 'text',
        },
        {
          label: 'Upsell visibility',
          name: 'upsellVisibility',
          component: 'select',
          options: [
            {
              label: 'Under product details',
              value: 'underProductDetails',
            },
            {
              label: 'Under product gallery',
              value: 'underProductGallery',
            },
            {
              label: 'Hidden',
              value: 'hidden',
            },
          ],
        },
        {
          label: 'Product Item Settings',
          name: 'productItem',
          component: 'group',
          description: 'Star rating, color variant selector, quick shop',
          fields: [
            {
              label: 'Enable Star Rating',
              name: 'enabledStarRating',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Enable Color Variant Selector',
              name: 'enabledColorSelector',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
  
            {
              label: 'Enable Color Name On Hover',
              name: 'enabledColorNameOnHover',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Enable Quick Shop',
              name: 'enabledQuickShop',
              component: 'toggle',
              description:
                'Quick shop is hidden on mobile and will only show if the product item has only one variant or multiple variants through a single option, e.g. "Size',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Enable Quick Shop Back In Stock Notification',
              name: 'enabledQuickShopBIS',
              component: 'toggle',
              description:
                'Displays a "Notify Me" button on quick shop for sold out products',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
          ],
          defaultValue: {
            enabledStarRating: false,
            enabledColorSelector: true,
            enabledColorNameOnHover: false,
            enabledQuickShop: true,
            enabledQuickShopBIS: false,
          },
        },
      ],
      defaultValue: {
        message: 'Complete the look',
        upsellVisibility: 'underProductDetails',
      },
    },
    {
      label: 'Similar Products',
      name: 'similarProducts',
      component: 'group',
      description: 'Similar products settigs',
      fields: [
        {
          label: 'Enable Similar Products',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
          defaultValue: 'Products Slider Heading',
        },
        {
          label: 'Product Item Settings',
          name: 'productItem',
          component: 'group',
          description: 'Star rating, color variant selector, quick shop',
          fields: [
            {
              label: 'Enable Star Rating',
              name: 'enabledStarRating',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Enable Color Variant Selector',
              name: 'enabledColorSelector',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
  
            {
              label: 'Enable Color Name On Hover',
              name: 'enabledColorNameOnHover',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Enable Quick Shop',
              name: 'enabledQuickShop',
              component: 'toggle',
              description:
                'Quick shop is hidden on mobile and will only show if the product item has only one variant or multiple variants through a single option, e.g. "Size',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Enable Quick Shop Back In Stock Notification',
              name: 'enabledQuickShopBIS',
              component: 'toggle',
              description:
                'Displays a "Notify Me" button on quick shop for sold out products',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
          ],
          defaultValue: {
            enabledStarRating: false,
            enabledColorSelector: true,
            enabledColorNameOnHover: false,
            enabledQuickShop: true,
            enabledQuickShopBIS: false,
          },
        },
        {
          label: 'Slider Settings',
          name: 'slider',
          component: 'group',
          description: 'Slider style, slides per view',
          fields: [
            {
              label: 'Slider Style',
              name: 'sliderStyle',
              component: 'select',
              description:
                'Loop and centered settings only apply if the number of products is at least twice the number of slides per view',
              options: [
                { label: 'Contained', value: 'contained' },
                { label: 'Contained w/ Loop', value: 'containedWithLoop' },
                { label: 'Full Bleed, Centered w/ Loop', value: 'fullBleed' },
                {
                  label: 'Full Bleed, Centered w/ Loop & Gradient',
                  value: 'fullBleedWithGradient',
                },
              ],
            },
            {
              label: 'Slides Per View (desktop)',
              name: 'slidesPerViewDesktop',
              component: 'number',
            },
            {
              label: 'Slides Per View (tablet)',
              name: 'slidesPerViewTablet',
              component: 'number',
              description: 'Tip: use decimals to show partial slides',
            },
            {
              label: 'Slides Per View (mobile)',
              name: 'slidesPerViewMobile',
              component: 'number',
              description: 'Tip: use decimals to show partial slides',
            },
            {
              label: 'Slider Navigation (desktop)',
              name: 'sliderNavigationDesktop',
              component: 'select',
              options: [
                { label: 'None', value: 'none' },
                { label: 'Arrows', value: 'arrows' },
                { label: 'Dots', value: 'dots' },
                { label: 'Both', value: 'both' },
              ],
            },
            {
              label: 'Slider Navigation (mobile)',
              name: 'sliderNavigationMobile',
              component: 'select',
              options: [
                { label: 'None', value: 'none' },
                { label: 'Arrows', value: 'arrows' },
                { label: 'Dots', value: 'dots' },
                { label: 'Both', value: 'both' },
              ],
            },
          ],
          defaultValue: {
            sliderStyle: 'contained',
            slidesPerViewDesktop: 5,
            slidesPerViewTablet: 3.4,
            slidesPerViewMobile: 1.4,
            sliderNavigationDesktop: 'arrows',
            sliderNavigationMobile: 'arrows',
          },
        },
        {
          label: 'Section Settings',
          name: 'section',
          component: 'group',
          description: 'Full width',
          fields: [
            {
              label: 'Full Width',
              name: 'fullWidth',
              component: 'toggle',
              description: 'Removes max width from contained styles',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Spacing',
              name: 'spacing',
              component: 'select',
              description: 'Addjust space around this section',
              options: [
                { label: 'Vertical padding', value: 'py-contained' },
                { label: 'Remove top padding', value: 'py-contained !pt-0' },
                { label: 'Remove bottom padding', value: 'py-contained !pb-0' },
              ],
              defaultValue: 'py-contained',
            },
          ],
          defaultValue: {
            buttonStyle: 'btn-primary',
            fullWidth: false,
          },
        },
      ],
    }
  ],
};
