import { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { ReviewStars } from '../ReviewStars';
import { ProductReviewsFilter } from './ProductReviewsFilter';
import { ProductReviewsPagination } from './ProductReviewsPagination';
import { ProductReviewsReviews } from './ProductReviewsReviews';
import { Spinner } from '../';

// Utility functions
const mapAverageScoreToSize = (averageScore) => {
  switch (averageScore) {
    case 1:
      return 'Small';
    case 2:
      return 'True to size';
    case 3:
      return 'Big';
    default:
      return '';
  }
};

export function ProductReviews({ legacyResourceId, stickyTopClass }) {
  const initialFetchCompleted = useRef(false);
  const reviewsRef = useRef(null);

  // State variables
  const [reviewAggregate, setReviewAggregate] = useState(null);
  const [topMentionedTopics, setTopMentionedTopics] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [newPage, setNewPage] = useState(1);
  const [allReviewsLoaded, setAllReviewsLoaded] = useState(false);
  const [allReviewsData, setAllReviewsData] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [filteredTotalPages, setFilteredTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRate, setSelectedRate] = useState(null);
  const [clearAllFilters, setClearAllFilters] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isReviewsLoading, setIsReviewsLoading] = useState(false);
  const [reviewsHeight, setReviewsHeight] = useState(0);

  useEffect(() => {
    if (reviewsRef.current) {
      setReviewsHeight(reviewsRef.current.clientHeight);
    }
  }, [allReviewsData, filteredReviews, currentPage, isReviewsLoading]);

  const reviewsPerPage = 12;
  const totalPages= allReviewsLoaded ? Math.ceil(allReviewsData.length / reviewsPerPage) : Math.ceil(reviewAggregate?.count / reviewsPerPage);
  const totalFilteredPages = Math.ceil(filteredReviews.length / reviewsPerPage);
  
  const fetchReviewAggregate = useCallback(async (productId, page = 1, perPage = 150, accumulatedReviews = []) => {
    try {
      if (!productId) return;

      page = allReviewsLoaded ? page : newPage;
      perPage = allReviewsLoaded ? perPage : 12;
  
      const endpoint = '/api/yotpo/getProductReviews';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getProductReviews',
          productId,
          page, 
          per_page: perPage, 
        }),
      };
  
      const response = await fetch(endpoint, options);
      const data = await response.json();
  
      if (!data || !data.response || !data.response.bottomline) {
        throw new Error('No data returned from fetchReviewAggregate');
      }
  
      const customFields = data.response.bottomline.custom_fields_bottomline || {};
      const customFieldFitId = '--95085';
      let customFieldFitTitle = '';
      if (customFields && customFields[customFieldFitId] && customFields[customFieldFitId].title) {
        customFieldFitTitle = customFields[customFieldFitId].title;
      }
  
      // Get the average_score inside custom_fields_bottomline and transform it
      const averageScoreInsideCustomField = customFields[customFieldFitId]?.average_score ?? null;
      const size = mapAverageScoreToSize(averageScoreInsideCustomField);
  
      // Get the reviews data
      const reviews = data.response.reviews || [];
  
      const updatedReviews = [...accumulatedReviews, ...reviews];

      // Uncomment this code to add product names to the reviews
      // const groups = data.response.grouping_data || [];
      // const addProductNameToReviews = (updatedReviews, groups) => {
      //   updatedReviews.forEach(review => {
      //     const id = review.id.toString();
      //     if (groups[id]) {
      //       review.product_name = groups[id].product_name;
      //     }
      //   });
      // };
      // addProductNameToReviews(updatedReviews, groups);
      // console.log('updatedReviews after adding product names', updatedReviews);

      if (allReviewsLoaded) {
        console.log('All reviews loaded');
        if (page < Math.ceil(data.response.bottomline.total_review / perPage)) {
          // Fetch the next page of reviews recursively
          return fetchReviewAggregate(productId, page + 1, 150, updatedReviews);
        } else {
          // If all pages have been fetched, set the allReviewsData
          setAllReviewsData(updatedReviews);
        }
      } else {
        setAllReviewsData(reviews); // Set the allReviewsData with the fetched reviews
      }
  
      // Set the review aggregate data in the state
      setReviewAggregate({
        rating: data.response.bottomline.average_score,
        count: data.response.bottomline.total_review,
        starDistribution: data.response.bottomline.star_distribution,
        customFieldFitTitle,
        size,
      });
  
      // Set the top mentioned topics data in the state
      setTopMentionedTopics(data.topMentionedTopics.top_topics.top_mention_topics);
      return data; // Return the data object after setting the state
    } catch (error) {
      console.error(`fetchReviewAggregate error: ${error.message}`);
      throw error;
    }
  }, [allReviewsLoaded, newPage]);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!initialFetchCompleted.current) {
          setIsLoading(true);
        }
        setIsReviewsLoading(true);
        await fetchReviewAggregate(legacyResourceId);
        if (!initialFetchCompleted.current) {
          setIsLoading(false); // Hide loading state once data is fetched
          initialFetchCompleted.current = true; // Mark initial fetch as completed
        }
        setIsReviewsLoading(false);
      } catch (error) {
        if (!initialFetchCompleted.current) {
          setIsLoading(false); // Hide loading state in case of error
        }
        setIsReviewsLoading(false);
        setFetchError('An error occurred while fetching data.'); // Set the error message
      }
    };

    fetchData();
  }, [fetchReviewAggregate, legacyResourceId]);

  return allReviewsData.length > 0 && (
    <div id="product-reviews" className="flex min-h-[1rem] flex-wrap flex-col gap-5">
      {isLoading ? ( // Show loading state
        <div className="relative flex min-h-[20rem] items-center justify-center">
          <Spinner width="32" />
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10">
          <div>
            <div className={`md:sticky ${stickyTopClass} overflow-hidden`}>
              <div className="flex flex-col lg:flex-nowrap lg:flex-row gap-5">
                <div className="flex flex-col gap-[5px]">
                  <h2 className="text-title-h2">Customer Reviews</h2>
                  <h3 className="text-title-h3">{reviewAggregate.rating.toFixed(1)}</h3>
                  <ReviewStars rating={reviewAggregate.rating} size="large" />
                  <p className="text-2xs text-mediumDarkGray">
                    {reviewAggregate.count} Reviews
                  </p>
                  {reviewAggregate.customFieldFitTitle && (
                    <p className="text-mediumDarkGray">
                      {reviewAggregate.customFieldFitTitle}:
                      {reviewAggregate.size && (
                        <span className="font-medium ml-1">{reviewAggregate.size}</span>
                      )}
                    </p>
                  )}
                </div>
                <ProductReviewsFilter
                  selectedRate={selectedRate}
                  searchQuery={searchQuery}
                  starDistribution={reviewAggregate.starDistribution}
                  topMentionedTopics={topMentionedTopics}
                  selectedTopic={selectedTopic}
                  allReviewsData={allReviewsData}
                  allReviewsLoaded={allReviewsLoaded}
                  setSearchQuery={setSearchQuery}
                  setSelectedTopic={setSelectedTopic}
                  setSelectedRate={setSelectedRate}
                  setClearAllFilters={setClearAllFilters}
                  setFilteredTotalPages={setFilteredTotalPages}
                  setCurrentPage={setCurrentPage}
                  setFilteredReviews={setFilteredReviews}
                  setAllReviewsLoaded={setAllReviewsLoaded}
                  clearAllFilters={clearAllFilters}
                  reviewsPerPage={reviewsPerPage}
                  reviewCount={reviewAggregate.count}
                />
              </div>
            </div>
          </div>
          <div className="flex-wrap flex-col gap-5">
            {fetchError ? ( 
              <div>
                <p>{fetchError}</p>
              </div>
            ) : (
              isReviewsLoading ? (
                <div className="relative flex items-center justify-center bg-offWhite" style={{ minHeight: reviewsHeight }}>
                  <Spinner width="32" />
                </div>
              ) : (
                <div ref={reviewsRef}>
                  <ProductReviewsReviews
                    currentPage={currentPage}
                    reviewsPerPage={reviewsPerPage}
                    selectedRate={selectedRate}
                    selectedTopic={selectedTopic}
                    searchQuery={searchQuery}
                    allReviewsLoaded={allReviewsLoaded}
                    allReviewsData={allReviewsData}
                    filteredReviews={filteredReviews}
                    reviewCount={reviewAggregate.count}
                  />
                </div>
              )
            )}
            {totalPages > 1 && (
              <div className="w-full flex items-center">
                <ProductReviewsPagination
                  reviewsPerPage={reviewsPerPage}
                  totalPages={totalPages}
                  totalFilteredPages={totalFilteredPages}
                  filteredTotalPages={filteredTotalPages}
                  currentPage={currentPage}
                  newPage={newPage}
                  allReviewsLoaded={allReviewsLoaded}
                  setCurrentPage={setCurrentPage}
                  setNewPage={setNewPage}
                  reviewsRef={reviewsRef} 
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

ProductReviews.displayName = 'ProductReviews';
ProductReviews.propTypes = {
  legacyResourceId: PropTypes.string,
};
