import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';

import { RatingBarGraph } from './RatingBarGraph';
import { Svg } from '../Svg';

export function ProductReviewsFilter({
  selectedRate, 
  searchQuery,
  starDistribution,
  topMentionedTopics, 
  selectedTopic,
  allReviewsData,
  setSearchQuery,
  setSelectedTopic,
  setSelectedRate,
  setClearAllFilters,
  setFilteredTotalPages,
  setCurrentPage,
  setFilteredReviews,
  setAllReviewsLoaded,
  clearAllFilters,
  reviewsPerPage,
  reviewCount,
}){

  const starDistributionObject = starDistribution ? Object.keys(starDistribution).map(key => ({
    rating: parseInt(key),
    count: starDistribution[key]
  })) : [];

  // Event handlers
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSelectedTopic(null);
    setSelectedRate(null);
    setAllReviewsLoaded(true);
  };

  const handleTopicClick = (topicName) => {
    setSelectedTopic(topicName);
    setSelectedRate(null);
    setSearchQuery('');
    setAllReviewsLoaded(true);
  };

  const handleRateClick = (rate) => {
    setSelectedRate(rate);
    setSelectedTopic(null);
    setSearchQuery('');
    setAllReviewsLoaded(true);
  };

  const handleClearAllFilters = () => {
    setSelectedTopic(null);
    setSelectedRate(null);
    setSearchQuery('');
    setClearAllFilters(false);
  };

  useEffect(() => {
    if (clearAllFilters) {
      setSelectedTopic(null);
      setSelectedRate(null);
      setSearchQuery('');
      setClearAllFilters(false);
    } else if (selectedRate) {
      const filteredReviewIds = allReviewsData
        .map((review, index) => (review.score === selectedRate) ? index : -1)
        .filter((index) => index !== -1);
  
      setFilteredReviews(filteredReviewIds);
  
      setFilteredTotalPages(Math.ceil(filteredReviewIds.length / reviewsPerPage));
  
      setCurrentPage(1);
    } 
    else if (selectedTopic) {
      const filteredReviewIds = allReviewsData
        .map((review, index) => (review.title.toLowerCase().includes(selectedTopic.toLowerCase()) ||
          review.content.toLowerCase().includes(selectedTopic.toLowerCase())) ? index : -1)
        .filter((index) => index !== -1);

      setFilteredReviews(filteredReviewIds);

      setFilteredTotalPages(Math.ceil(filteredReviewIds.length / reviewsPerPage));

      setCurrentPage(1);
    } else if (searchQuery) {
      const fuseOptions = {
        keys: ['title', 'content'],
        threshold: 0.4,
      };
      const fuse = new Fuse(allReviewsData, fuseOptions);
      const searchResults = fuse.search(searchQuery);
  
      const filteredReviewIds = searchResults.map((result) => {
        const review = result.item;
        return allReviewsData.findIndex(
          (item) => item.id === review.id
        ); // Get the index of the review with matching id
      });
  
      setFilteredReviews(filteredReviewIds);
  
      setFilteredTotalPages(Math.ceil(filteredReviewIds.length / reviewsPerPage));
  
      setCurrentPage(1);
    } else {
      setFilteredReviews([]);
      setFilteredTotalPages(0);
    }
  }, [clearAllFilters, selectedRate, selectedTopic, searchQuery, allReviewsData, reviewsPerPage]); 

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col xl:flex-row gap-5">
          <RatingBarGraph 
            ratingCounts={starDistributionObject}
            selectedRate={selectedRate}
            onRateClick={handleRateClick}
          />
          {reviewCount > reviewsPerPage && topMentionedTopics.length > 0 && (
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-[5px]">
                <p className='font-medium'>Top Mentioned Topics</p>
                <div className="flex flex-wrap gap-[5px] p-[.5px]">
                  {topMentionedTopics.map((topic) => (
                    <button
                      aria-label={`Filter by topic: ${topic.name}`}
                      key={topic.name}
                      className={`flex max-w-full items-center shadow-hairline bg-offWhite py-1 px-1.5 text-xs transition md:hover:bg-lightGray ${
                        topic.name === selectedTopic ? 'bg-primary text-white' : ''
                      }`}
                      onClick={() => handleTopicClick(topic.name)}
                      type="button"
                    >
                      {topic.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-[5px] p-[.5px]">
          <p className='font-medium'>Search</p>
          <div className="group max-w-[calc(100%-.5px)] bg-offWhite hover:bg-white active:bg-white relative h-12 flex justify-between gap-2.5 shadow-hairline p-2.5">
            <Svg
              className="w-5 text-text"
              src="/svgs/search.svg#search"
              alt="Search Icon"
              title="Search"
              viewBox="0 0 32 32"
            />
            <label htmlFor="search-reviews" className="sr-only">Search</label>
            <input
              aria-label="Search reviews here"
              className="bg-offWhite group-hover:bg-white active:bg-white min-w-0 flex-1 py-3 text-base outline-none"
              onChange={handleSearchChange}
              type="text"
              id="search-reviews"
              name="search-reviews"
              placeholder="Search reviews"
              value={searchQuery}
            />
          </div>
        </div>
      </div>
      {selectedRate || selectedTopic || searchQuery ? (
        <button
          className="w-fit text-xs text-main-underline"
          onClick={handleClearAllFilters}
          aria-label="Clear all filters"
          type="button"
        >
          Clear Results
        </button>
      ) : null}
    </div>
  )
}

ProductReviewsFilter.displayName = 'ProductReviewsFilter';
ProductReviewsFilter.propTypes = {
  selectedRate: PropTypes.number,
  searchQuery: PropTypes.string,
  topMentionedTopics: PropTypes.array.isRequired,
  selectedTopic: PropTypes.string,
  allReviewsData: PropTypes.array.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setSelectedTopic: PropTypes.func.isRequired,
  setSelectedRate: PropTypes.func.isRequired,
  setClearAllFilters: PropTypes.func.isRequired,
  setFilteredTotalPages: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setFilteredReviews: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.bool.isRequired,
  reviewsPerPage: PropTypes.number.isRequired,
};