import { ProductReviewsReview } from './ProductReviewsReview';

export function ProductReviewsReviews ({
  currentPage,
  reviewsPerPage,
  selectedRate,
  selectedTopic,
  searchQuery,
  allReviewsData,
  filteredReviews,
  reviewCount,
}){
  const startIndex = (currentPage - 1) * reviewsPerPage;
  const endIndex = startIndex + reviewsPerPage;
  let currentReviews = [];

  if (selectedRate || selectedTopic || searchQuery) {
    currentReviews = filteredReviews
      .slice(startIndex, endIndex)
      .map((reviewId) => allReviewsData[reviewId]);
  } else {
    currentReviews = allReviewsData.slice(startIndex, endIndex);
  }
  if (currentReviews.length === 0) {
    return <p>No results found.</p>;
  }

  return (
    <>
      <div className="flex mb-5">
        {reviewCount > 1 && (
          <>
          {selectedRate || selectedTopic || searchQuery ? (
            <p className="text-mediumDarkGray">
              Total Reviews Shown: <span className="font-medium">{filteredReviews.length}</span> of <span className="font-medium">{reviewCount}</span>
            </p>
          ) : (
            <p className="text-mediumDarkGray">Total Reviews: <span className="font-medium">{reviewCount}</span></p>
          )}
          </>
        )}
      </div>
      
      <div className="flex flex-col">
        {currentReviews.map((review) => (
          <ProductReviewsReview
            review={review}
            key={review.id}
          />
        ))}
      </div>
    </>
  );
}

ProductReviewsReviews.displayName = 'ProductReviewsReviews';